<template>
  <div v-loading="isSubmitting">

    <el-form
      :model="contactUsForm"
      :rules="rules"
      label-position="top"
      ref="contactUsForm"
      label-width="120px"
      class="demo-contactUsForm"
    >
      <div class="row">
        <div class="col-md-6">
          <el-form-item
            label="Full name"
            prop="name"
          >
            <el-input v-model="contactUsForm.name"></el-input>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item
            label="Email"
            prop="email"
          >
            <el-input v-model="contactUsForm.email"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <el-form-item
            label="Phone Number"
            prop="phone_number"
          >
            <el-input v-model="contactUsForm.phone_number"></el-input>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item
            label="Address"
            prop="company"
          >
            <el-input v-model="contactUsForm.company"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <el-form-item
            label="message"
            prop="message"
          >
            <el-input
              type="textarea"
              v-model="contactUsForm.message"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <el-form-item>
        <el-button
          style="background-color: #996727; color: white;"
          @click="submitForm('contactUsForm')"
        >Send Message <i class="el-icon-d-arrow-right"></i></el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSubmitting: false,
      contactUsForm: {
        name: "",
        email: "",
        phone_number: "",
        company: "",
        message: "",
      },

      rules: {
        name: [
          {
            required: true,
            message: "Full Name is required",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
        ],
        phone_number: [
          {
            required: true,
            message: "Phone Number is required",
            trigger: "blur",
          },
        ],
        company: [
          {
            required: true,
            message: "Address is required",
            trigger: "blur",
          },
        ],
        message: [
          {
            required: true,
            message: "Message is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.isSubmitting = true;
            let request = await this.$http.post(
              `api/contact/add`,
              this.contactUsForm
            );
            if (
              request.data.success &&
              request.data.message == "MESSAGE_SENT_SUCCESSFULLY"
            ) {
              this.$notify({
                title: "Success",
                message: "Your Message is Submitted Successfully",
                type: "success",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isSubmitting = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isSubmitting = false;
            this.$notify({
              title: "Submission Failed",
              message: "Unable to Submit Message, Please try again",
              type: "error",
            });
          } finally {
            this.isSubmitting = false;
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
</style>